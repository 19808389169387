import Header from "../Header/Header";
import classes from "./VerifyWallet.module.css";
import heroLeft from "./../../img/heroLeftImg.png";
import heroRight from "./../../img/heroRightImg.png";
import profile from "./../../img/profile.png";
import { useState, useEffect, useRef } from "react";
import { FaDiscord } from "react-icons/fa";
import axios from "axios";
import Logo from "../Header/logo";
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import * as buffer from "buffer";

import { Toggle } from 'rsuite';
import "rsuite/dist/rsuite.css";

import {
  Connection,
  Keypair,
  SystemProgram,
  LAMPORTS_PER_SOL,
  Transaction,
  sendAndConfirmTransaction,
  TransactionSignature,
} from "@solana/web3.js";
// import { log } from "console";

window.Buffer = buffer.Buffer;


const apiUrl = process.env.REACT_APP_BACKEND_API;


const VerifyWallet = () => {
  const [loginDiscord, setIsLoginDiscord] = useState(null);
  const [signedMessage, setSignedMessage] = useState(null);
  // const [userInfo, setUserInfo] = useState(null);
  const [userGuilds, setUserGuilds] = useState(null);
  const [walletVerifiedMessage, setIsWalletVerifiedMessage] = useState(false);
  const { connected, signMessage, publicKey, sendTransaction } = useWallet();
  const [data, setData] = useState(null);
  const [isLedger, setIsLedger] = useState(false);
  let { connection } = useConnection();

  const logged = useRef(false);


  // useEffect(() => {
  //   if (!connected) setStatus(null);
  // }, [connected]);

  // useEffect(() => {
  //   if (!loginDiscord) setIsLoginDiscord(null);
  // }, [loginDiscord]);

  // useEffect(() => {
  //   if (!userGuilds) setUserGuilds(null);
  // }, [userGuilds]);

  useEffect(() => {
    if (logged.current === false) {
      validateDiscordUser();
      logged.current = true;
    }
  }, []);

  const validateDiscordUser = async () => {

    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];
    //console.log(accessToken);
    //let userInfo = {};
      if (!accessToken && !tokenType) return;
      // console.log('callind discord api');

      const userResponse = await axios.get('https://discord.com/api/users/@me', {
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        }
      });

      setIsLoginDiscord(userResponse);

      //get users guild
      const guildsResponse = await axios.get('https://discord.com/api/users/@me/guilds', {
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        }
      });
      setUserGuilds(guildsResponse);

  }

  const sign = async () => {
    if (!connected) return;

    try {
      const dataNew = await getMessage();
      //console.log(data)
      //console.log(dataNew)
      //if (!data) { return 'sorry an error happened getting message.' }
      const nonceEncoded = new TextEncoder().encode(btoa(JSON.stringify(dataNew.data)));
      const signed = await signMessage(nonceEncoded);
      const res = await axios({
        method: "post",
        url: apiUrl + "/Sign?code=jj7w1X5sQTPH0CwwtgU-2oZTFg7gXKoBBclYUAC8PmUvAzFu4uxTEg==",
        data: {
          signature: toHexString(signed),
          publicKey: publicKey,
          data: dataNew,
          userInfo: loginDiscord.data,
          userGuilds: userGuilds.data
        },
      });
      //console.log(res);
      setSignedMessage({
        status: "success",
        message: res.data,
      });
    } catch (error) {
      console.log(`error ${error}`);
      setSignedMessage({
        status: "error",
        message: error.response.data,
      });
      //TODO: LOG TO DISCORD

    }
  };

  const ledgerSignin = async () => {
    if (!connected) return;
    try {
      const dataNew = await getMessage();
      const trx = await ledgerTransaction();
      const res = await axios({
        method: "post",
        url: apiUrl + "/Sign?code=jj7w1X5sQTPH0CwwtgU-2oZTFg7gXKoBBclYUAC8PmUvAzFu4uxTEg==",
        data: {
          signature: trx,
          publicKey: publicKey,
          data: dataNew,
          userInfo: loginDiscord.data,
          userGuilds: userGuilds.data,
          isLedger: true
        },
      });
      setSignedMessage({
        status: "success",
        message: res.data,
      });
    } catch (error) {
      console.log(`error ${error}`);
      setSignedMessage({
        status: "error",
        message: 'Sorry an error happened during this operation.',
      });
    }
  }

  const getMessage = async () => {
    // setData(null)
    const result = await axios({
      method: "post",
      url: apiUrl + "/GetMessage?code=HP6cP2izgo56hUEVv29JWnMsKfytc0SJSq6nNw68rACvAzFuuNggvw==",
      data: {
        publicKey: publicKey,
      },
    });
    // console.log(`data from api ${result.data}`)
    setData({
      data: result.data
    });

    return result;
  }

  const ledgerTransaction = async () => {
    // const fromKeypair = Keypair.generate();
    // const toKeypair = Keypair.generate();
    const rpcUrl = process.env.REACT_APP_RPC_URL

    connection = new Connection(
      rpcUrl,
      "confirmed"
    );

    // const airdropSignature = await connection.requestAirdrop(
    //   fromKeypair.publicKey,
    //   LAMPORTS_PER_SOL
    // );

    // await connection.confirmTransaction(airdropSignature);

    const lamportsToSend = 1_000_0;
    let signature = '';
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: publicKey,
        lamports: lamportsToSend,
      })
    );
    // console.log(connection);
    signature = await sendTransaction(transaction, connection);

    await connection.confirmTransaction(signature, 'confirmed');
    // const tx = await sendAndConfirmTransaction(connection, transferTransaction, [
    //   fromKeypair,
    // ]);

    //console.log(signature);
    return signature;

  }

  const toHexString = (buffer) => {
    return buffer.reduce(
      (str, byte) => str + byte.toString(16).padStart(2, "0"),
      ""
    );
  };

  return (
    <>
      <div className={classes.verifyTop}>
        <img className={classes.imgLeft} src={heroLeft} alt="heroLeft" />
        <img className={classes.imgRight} src={heroRight} alt="heroRight" />
        <Header />
        <div className={classes.heroTop}>
          <div className={classes.innerContent}>
            <h1>Tektools</h1>
            <p>Wallet Verification</p>
            {!loginDiscord && (

              <div>
                <a className="tbd" href={process.env.REACT_APP_DISCORD_AUTH_URL}>
                  <FaDiscord className='fancy-link__icon' /> Login with Discord
                </a>
              </div>

            )}
          </div>
        </div>

        {/* discord login here ============= */}
        {loginDiscord && (
          <div className={classes.discordLogin}>
            <div className={classes.profilePic}>
              <img src={`https://cdn.discordapp.com/avatars/${loginDiscord.data.id}/${loginDiscord.data.avatar}.jpg`} id="avatar" alt="profile" />
              <p>{loginDiscord.data.username}</p>
            </div>
            {!publicKey && (
              <h1>Connect wallet to start</h1>
            )}
          </div>
        )}

        {/* your wallet is verify  ====== */}
        {loginDiscord && (
          <div className={classes.toggle}>
            <label><h5>Ledger Wallet?</h5></label>
            <Toggle size="lg" checkedChildren="Yes" unCheckedChildren="No" onChange={(value) => { setIsLedger(value) }} />
          </div>)}
        {isLedger === true ? (
          <button className={classes.signMessage} onClick={ledgerSignin}>Sign Ledger Transaction</button>

        ) : (
          <>
            {loginDiscord && publicKey && !signedMessage && (
              <button className={classes.signMessage} onClick={sign}>Sign Message</button>
            )}
          </>
        )}

        {signedMessage && <div className={classes.walletVerified}>
          <h3>
            {/* Your Wallet is verified, you can safely close this window now.{" "} */}
            {signedMessage.message}
          </h3>
        </div>}
        {/* sign message ============== */}


        <div className={classes.footer}>
          <p>
            © 2023. All rights reserved by{" "}
            <a href="https://tektools.app">Tektools.</a>
          </p>
          <ul>
            <li>
              <a href="/pricay-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/terms-use">Terms of Use</a>
            </li>
          </ul>
        </div>
      </div >
    </>
  );
};
export default VerifyWallet;

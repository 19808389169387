import classes from './Header.module.css';
import logo from './../../img/logo200x60.png';

import {
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

require("@solana/wallet-adapter-react-ui/styles.css");

const Header  = () => {
    return (
        <div className={classes.headerTop}>
           <img src={logo} alt='logo'  />
           {/* <button>Select Wallet</button> */}
           <WalletMultiButton />

        </div>
    )
}
export default Header;